<template>
  <div>
    <app-api-call
      class="ma-0 pa-0 mt-4"
      :call-result="callResult"
    />
    <v-data-table
      v-if="callResult.finished"
      :headers="headers"
      :items="items"
      :search="search"
      :loading="!callResult.finished"
      :sort-by="['date']"
      :sort-desc="[true]"
      :mobile-breakpoint="0"
      class="ma-0 pa-0"
      dense
    >
      <template v-slot:top>
        <v-toolbar
          flat
          color="white ma-0 pa-0 mb-3 pr-3"
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$i18n.t('$l.app.search')"
            single-line
            hide-details
          />
          <v-spacer />
        </v-toolbar>
      </template>
      <template v-slot:[`item.result`]="{ item }">
        <div :class="getResuoltClass(item)">
          {{ Math.round((item.score / item.quizQuestionsCount) * 100) }}%
          <v-icon
            v-if="passedTest(item)"
            color="light-green"
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            v-else
            color="red darken-1"
          >
            mdi-note-remove
          </v-icon>
        </div>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span style="white-space: nowrap">{{ item.date?$utils.formatDate(item.date):null }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Users',
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      headers: [
        { text: this.$i18n.t('$l.quiz.name'), value: 'name', type: 'text' },
        { text: this.$i18n.t('$l.quiz.rightAnswers'), value: 'score' },
        { text: this.$i18n.t('$l.quiz.questionsCount'), value: 'quizQuestionsCount' },
        { text: this.$i18n.t('$l.quiz.result'), value: 'result', type: 'text', sortable: false },
        { text: this.$i18n.t('$l.quiz.date'), value: 'date', type: 'text' }
      ],
      items: null,
      search: '',
      loading: false
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('education/quizResults')
        .then(r => {
          if (r.data) {
            r.data.forEach(response => {
              response.data = JSON.parse(response.data)
            })
            this.items = r.data
          }
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    },
    getResuoltClass (item) {
      return this.passedTest(item) ? 'light-green--text' : 'red--text darken-1'
    },
    passedTest (item) {
      return item.score >= item.scoreToPass
    }
  }
}
</script>
