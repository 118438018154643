<template>
  <v-container
    fluid
  >
    <basic-card
      title="$l.examination.availableTests"
      class="mt-9"
    >
      <app-api-call
        class="ma-0 pa-0 mt-4"
        :call-result="callResult"
      />
      <v-row
        justify="center"
      >
        <template
          v-if="availableCourses && availableCourses.length==0"
        >
          {{ $i18n.t('$l.quiz.NoAvailableCourses') }}
        </template>
        <v-col
          v-for="(course, idx) in availableCourses"
          :key="idx"
          sm="12"
          md="6"
          lg="4"
        >
          <QuizCard
            :coursedata="course"
            :owned="true"
            :image="course.imageSrc"
            class="card-carousel--card text-center"
            :course-path="'quiz/' + course.id"
          />
        </v-col>
      </v-row>
    </basic-card>
    <basic-card
      title="$l.examination.archiveOfTests"
      class="mt-9"
    >
      <QuizArchiveTable
        v-if="availableCourses"
        :data="availableCourses"
      />
    </basic-card>
  </v-container>
</template>

<script>
import QuizCard from './QuizCard.vue'
import QuizArchiveTable from './QuizArchiveTable.vue'

export default {
  name: 'ExaminationWrapper',
  components: {
    QuizCard,
    QuizArchiveTable
  },
  data () {
    return {
      callResult: { finished: false, authorized: false, error: null, info: null },
      show: false,
      dialog: false,
      presentationSrc: null,
      availableCourses: null,
      currentOffset: 0,
      windowSize: 5,
      paginationFactor: 268
    }
  },
  computed: {
    atEndOfList () {
      return this.currentOffset <= (this.paginationFactor * -1) * (this.availableCourses.length - this.windowSize)
    },
    atHeadOfList () {
      return this.currentOffset === 0
    }
  },
  beforeCreate () {
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      this.callResult.finished = false
      this.callResult.error = null
      this.$xapi.get('education/activeQuizzes')
        .then(r => {
          this.availableCourses = r.data
          this.availableCourses.forEach(i => {
            i.data = JSON.parse(i.data)
            i.imageSrc = require(`@/assets/education/education5.jpeg`)
          })
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.loading = false
          this.callResult.finished = true
        })
    },
    moveCarousel (direction) {
      if (direction === 1 && !this.atEndOfList) {
        this.currentOffset -= this.paginationFactor
      } else if (direction === -1 && !this.atHeadOfList) {
        this.currentOffset += this.paginationFactor
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .i-frame-presentation{
    width: 100%;
    height: 700px;
  }
</style>

<style>
  .overlay {
    height: 0px;
    overflow: visible;
    background:none !important;
    z-index: 1000;
  }
</style>
