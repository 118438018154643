<template>
  <v-card
    class="course-card fade"

    @click="openCourse"
  >
    <v-img
      :src="coursedata.imageSrc"
      height="150px"
    />
    <v-card-title class="text-start pb-0">
      <div class="">
        <span class="d-flex justify-start headline">{{ coursedata.name }}</span>
        <span class="d-flex justify-start subtitle-1">{{ coursedata.data.shortDescription }}</span>
        <v-icon
          left
        >
          mdi-timer-sand
        </v-icon><span class=" subtitle-1">
          {{ coursedata.timeLimit + ' ' + $i18n.t('$l.quizzes.testQuiz.minutes') }}</span>
      </div>
    </v-card-title>

    <v-card-actions
      width="100%"
      class="card-actions"
    >
      <v-btn
        v-if="owned"
        text
        class="secondary"
        block
        @click="$emit('open-presentation', coursedata.path)"
      >
        {{ $i18n.t('$l.app.moreInfo') }}
      </v-btn>
      <div
        v-else-if="!owned"
      >
        <div class="course-prise">
          {{ coursedata.price }} €
        </div>
        <v-btn
          text
          class="secondary"
          @click="$emit('buy-presentation', coursedata.title)"
        >
          Kupit
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>>

<script>
export default {
  name: 'CourseCard',
  props: {
    coursedata: {
      type: Object,
      required: true
    },
    owned: {
      type: Boolean,
      default: false
    },
    coursePath: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      show: false,
      dialog: false
    }
  },
  methods: {
    openNewWindow () {
      window.open('/presentations/AML%20skoleni/index.html', '_blank', 'top=200,left=200, width=1300,height=700')
    },
    openCourse () {
      this.$router.push(this.coursePath)
    }
  }
}
</script>

<style>
  .fade {
    opacity:0.8;
    transition: all .5s ease;
  }
  .fade:hover {
    opacity:1;
  }
</style>
