<template>
  <ExaminationWrapper />
</template>

<script>
import ExaminationWrapper from '@/components/education/ExaminationWrapper'

export default {
  name: 'Education',
  components: {
    ExaminationWrapper
  }
}
</script>
